export default {
  hero_home:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2Fd88eee75f49d42019e92a0ccb0b40bb2',
  hero_home_mobile:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F3d7e9eda8d924589973ad7abfc05496a',
  forest:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F73e341954d0343f0a32724d6b1e8b269',
  forest_mobile:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F750bd1e159a24981bf481e9967fa39d5',
  mockup_how_it_works:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F5bd3eb56fbbe40c892d69c47cfb070de',
  mockup_how_it_works_mobile:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2Fc1a1b1b10f6344e1bbb92cbc3e2c33ee',
  mockup_how_it_works_bill_detailed:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F23c3fafdf3004a3aae5fec665067317a',
  mockup_how_it_works_bill_detailed_large:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F055c670c17274615832e04b6f5d038f3',
  mockup_how_it_works_comparison:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F85154ab230764151a4e15113ebdad75d',
  more_solar_panels:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2Fd0cc2bc4d1174c65912f01c73bcd9237',
  more_solar_panels_mobile:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F155701db0e17413e83d1497df69f4739',
  how_it_works_origin_energy:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F61bfdad948874a91a1834694e49cf5bc',
  how_it_works_origin_energy_mobile:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2Fe537a40523574da7bb3a8e404af68e83',
  lemons:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F7c29ba0f45cb4c9ca928ffe4f7a7526f',
  article_logo_oglobo:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F66c59822d40c4e2da482c0c4cd459bcd',
  article_logo_exame:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F6732068237bd45d298b4679da3ed996b',
  article_logo_valor:
    'https://cdn.builder.io/api/v1/image/assets%2Fdc56bb5c9915477aaa0a5b682d15be60%2F949064cc4dd64cebbfb0bfe199b40cf4',
}
